import React from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'

const ErrorWrapper = styled.div`
  padding: 60px 0 95px 0;
  h1 {
    margin-bottom: 3px;
    text-align: center;
  }
  h3 {
    margin-bottom: 98px;
    text-align: center;
  }
`

export default () => (
  <ErrorWrapper>
    <h1>404</h1>
    <h3>This page doesn&apos;t seem to exist.</h3>
    Return <Link to="/">Home</Link>
  </ErrorWrapper>
)
